import { Box } from '@mui/material';
import FloatingLocalDeviceMenu from '@src/_components/atoms/FloatingLocalDeviceMenu';
import FloatingVideoConfMenu from '@src/_components/atoms/FloatingVideoConfMenu';
import CallInvitation from '@src/_components/molecules/CallInvitation';
import ScannerUloadModal from '@src/_components/organisms/ScannerUpload';
import PolicyService from '@src/api-service/policy-service/PolicyService';
import SettingsService from '@src/api-service/settings-service/SettingsService';
import client from '@src/core-utils/Helper/appwrite';
import React, { useEffect, useState } from 'react';
import theme from '../../../core-utils/theme';
import MainFooter from '../../molecules/MainFooter';
import { useAppSelector } from '@src/Redux/Store/store';
import { RootStateOrAny } from 'react-redux';

interface IHomeTemplate {
  leftNavComponent?: any;
  header?: any;
  content?: any;
}

// As we scale up, we gonna fetch these details
// from the server based on the domain name of the
// vendor.
const DATABASE_ID = '66d2171d001cdd4ae180';
let INVITATION_COLLECTION_ID = '66d4773a003b2a7be748';

const HomeTemplate: React.FC<IHomeTemplate> = ({
  leftNavComponent,
  header,
  content,
}) => {
  const [showFloatingMenu, setShowFloatingMenu] = useState<boolean>(false);
  const [showScannerUploadModal, setShowScannerUploadModal] = useState<boolean>(false);
  const [showScannerMenu, setShowScannerMenu] = useState<boolean>(false);
  const [isWindows, setIsWindows] = useState<boolean>(false);

  const [showCallInvitation, setShowCallInvitation] = useState(false);
  const [invitationUserName, setInvitationUserName] = useState('');
  const [invitationUserEmail, setInvitationUserEmail] = useState('');
  const [invitationMeetingName, setInvitationMeetingName] = useState('');
  const [invitationMeetingUrl, setInvitationMeetingUrl] = useState('');

  const token = useAppSelector(
    (state: RootStateOrAny) => state.token,
  );

  useEffect(() => {
    SettingsService.getCollabToolsSettings()
      .then((resp) => {
        if (resp && resp?.resourceConnectionString) {
          // This is temporary for the demo
          const ALLOWED_DOMAINS = [
            'play.sonet.io',
            'dev.sonet.io'
          ];
          const ALLOWED_USERS = [
            'sonny@sonet.io',
            'sonny@play.sonet.io',
            'venu@sonet.io ',
            'venu.banda@sonet.io',
            'jeff@sonet.io',
            'jeff.burchett@gmail.com',
            'ram@sonet.io',
            'ramtest@play.sonet.io',
            'testuser@devint.com',
            'dev1@sonet.io'
          ];
          const hostname = window.location.hostname;
          const setupTeamsInterface = () => {
            setShowFloatingMenu(true);
            // This vendor has teams integration
            listenForIncomingCall(resp?.invitationCollectionId);
            requestSoundPermissionOnCall();
          }
          if (ALLOWED_DOMAINS.includes(hostname)) {
            if (ALLOWED_USERS.includes(token?.session?.username)) {
              setupTeamsInterface();
            }
          } else {
            setupTeamsInterface();
          }
        }
      });
    PolicyService.isScannerAllowedByPolicy()
      .then((resp) => {
        if (resp?.decision) {
          setShowScannerMenu(true);
        }
      })
  }, []);


  useEffect(() => {
    const checkPlatform = () => {
      const platform = navigator.platform.toLowerCase();
      setIsWindows(platform.includes('win'));
    };

    checkPlatform();
  }, []);

  useEffect(() => {
    if (isWindows) {
      SettingsService.persistDeviceHostName();
    }
  }, [isWindows]);

  const listenForIncomingCall = (invitationCollectionId: string = INVITATION_COLLECTION_ID) => {

    client.subscribe(`databases.${DATABASE_ID}.collections.${invitationCollectionId}.documents`, (event: any) => {
      const currentUser = localStorage.getItem('email');
      const domainName = window.location.hostname;
      console.log("Teams Join Notification", event, { from: event?.payload?.userId, to: currentUser });
      if (event?.payload?.userId !== currentUser || domainName !== event?.payload?.domainName || !event?.payload?.meetingUrl?.includes(domainName)) {
        return;
      }
      setShowCallInvitation(true);
      setInvitationUserName(event?.payload?.invitedBy);
      setInvitationUserEmail(event?.payload?.userId);
      setInvitationMeetingName(event?.payload?.meetingName);
      setInvitationMeetingUrl(event?.payload?.meetingUrl);
      sendPushNotification(event?.payload?.invitedBy ?? event?.payload?.userId, event?.payload?.meetingUrl);
      //Do not ring forever. Auto close after a minute
      setTimeout(() => {
        setShowCallInvitation(false);
      }, 60000);
    });
  };


  const sendPushNotification = (invitedBy: string, meetingUrl: string) => {
    console.log("SENDING PUSH NOTIFICATION", invitedBy, meetingUrl);
    const notificationMessage = `You've been invited to a teams meeting by ${invitedBy}.`;
    if (Notification.permission === "granted") {
      const n = new Notification(`${invitedBy} is trying to call you on Teams Meeting.`, {
        body: notificationMessage,
        icon: "/public/teams-icon.png" // Make sure to add an appropriate icon in your public folder
      });
      n.onclick = () => {
        window.open(meetingUrl, '_blank');
        window.focus();
      };
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          const n = new Notification(`${invitedBy} is trying to call you on Teams Meeting.`, {
            body: notificationMessage,
            icon: "/public/teams-icon.png" // Make sure to add an appropriate icon in your public folder
          });
          n.onclick = () => {
            window.open(meetingUrl, '_blank');
            window.focus();
          };
        }
      });
    }
  }

  async function requestSoundPermissionOnCall() {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });

    } catch (error) {
      console.error("Error handling sound permission:", error);
    }
  }

  const handleAccept = () => {
    // open meeting URl
    setShowCallInvitation(false);
    window.open(invitationMeetingUrl, '_blank');
  }

  const handleReject = () => {
    setShowCallInvitation(false);
  }


  return (
    <Box height="100%">
      {header}
      <Box
        display="flex"
        overflow="overlay"
        position="absolute"
        width="100%"
        height={`calc(100% - ${theme.spacing(16.25)})`}
      >
        <Box>{leftNavComponent}</Box>
        <Box id="box" display="flex" flexDirection="column" width="100%">
          <Box
            sx={{
              width: '100%',
              flex: '1 0 auto',
            }}
          >
            {content}
            {(isWindows && showScannerMenu) ? <ScannerUloadModal isOpen={showScannerUploadModal} onClose={() => setShowScannerUploadModal(false)} /> : null}
          </Box>
          <Box flexShrink={0} width="100%">
            <MainFooter
              versionRequired={true}
              logoRequired={false}
            ></MainFooter>
          </Box>
        </Box>
      </Box>
      {showFloatingMenu ? <FloatingVideoConfMenu /> : null}
      {(isWindows && showScannerMenu) ? <FloatingLocalDeviceMenu onClick={() => setShowScannerUploadModal(true)} /> : null}
      {showCallInvitation ? <CallInvitation name={invitationUserName} onAccept={handleAccept} onReject={handleReject} /> : null}
    </Box>
  );
};

export default HomeTemplate;
