import axios from '../axios';
import qs from 'qs';
import Config from '../../../config/config';

export interface ILoginDetails {
  username: string;
  password: string;
}

const LoginService = {
  login: async (loginDetails: ILoginDetails) => {
    const data = qs.stringify({
      username: loginDetails.username.toLowerCase(),
      password: loginDetails.password,
    });

    const config = {
      method: 'post',
      url: `${Config.BACKEND_BASE_URL}api/tokens`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    } as any;

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  validateAuthToken: async (token: any) => {
    const data = qs.stringify({
      token: token
    });

    const config = {
      method: 'post',
      url: `${Config.BACKEND_BASE_URL}api/tokens`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    } as any;

    try {
      const response = await axios(config);
      return response?.data?.authToken === token;
    } catch (error) {
      return error;
    }
  },
  deleteAuthToken: async (token: any) => {
    try {
      return await axios.delete(`${Config.BACKEND_BASE_URL}api/tokens/`);
    } catch (error) {
      return error;
    }
  },
};

export default LoginService;
