import { GLOBAL_LABELS } from '../constants';

const userModuleMessages = {
  CREATE_USER: {
    HEADING: 'Add User',
    UPDATE_HEADING: 'Update User',
    SUB_HEADING: 'Fill in the basic details of the user',
    REQUIRED: GLOBAL_LABELS.REQUIRED,
    TEXT_FIELD_LABELS: {
      FULL_NAME: 'Name',
      USER_NAME: 'Username',
      EMAIL_ID: 'Email Id',
      JOB_TITLE: 'Job Title',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      DESCRIPTION : 'Description',
      ENTITY_POPULATION_FIELD: 'Select User(s)',
      USER_ROLE :"User Role"
    },
    PLACEHOLDER_LABELS: {
      FULL_NAME_LABEL: 'Enter name here',
      USER_NAME_LABEL: 'Enter email Id here',
      JOB_TITLE_LABEL: 'Enter job title here',
      PASSWORD_LABEL: 'Enter password here',
      DESCRIPTION_LABEL : 'Enter description here',
      CONFIRM_PASSWORD_LABEL: 'Confirm Password here',
    },
    BUTTON_LABELS: {
      CANCEL: GLOBAL_LABELS.CANCEL,
      SAVE: GLOBAL_LABELS.SAVE,
      SAVING: GLOBAL_LABELS.SAVING,
    },
    ERROR_MESSAGES: {
      INVALID_EMAIL: 'Invalid Email Id',
      INVALID_PASSWORD: 'Password does not meet the required criteria',
      PASSWORD_NO_MATCH: 'Passwords do not match',
      USER_ALREADY_EXISTS: 'User already exists',
      ADD_USER_FAILED: 'Failed to add user',
      UPDATE_USER_FAILED: 'Failed to update user',
    },
  },
  USERS_DASHBOARD: {
    ADD_USER: 'Add Users',
    SUBHEADING: 'Select users you want to assign this group to.',
    SUB_HEADING_APP: 'Select users you want to assign this app to.',
  },
  LINK_USER_GROUP: {
    ADD_GROUPS: 'Add Groups',
    HELPER_TEXT:
      'Select groups to assign to this user. You may assign up to 50 groups per user.',
  },
  USER_STATUS: {
    ACTIVE: 'Active',
    INACTIVE: 'InActive',
  },
  EDIT_USER: {
    NAME: {
      HEADING: 'Edit Name',
      LABEL: 'Name',
    },
    EMAIL: {
      HEADING: 'Edit Email Id',
      LABEL: 'Email id',
    },
    JOB_TITLE: {
      HEADING: 'Edit Job Title',
      LABEL: 'Job Title',
    },
    PASSWORD: {
      HEADING: 'Change Password',
      LABEL_1: 'New Password',
      LABEL_2: 'Confirm Password',
    },
    USER_STATUS: {
      HEADING: 'Change User Status',
      LABEL: 'User Status',
    },
    DESCRIPTION: {
      HEADING: 'Edit Description',
      LABEL: 'Description',
    },
    PLACEHOLDER: {
      PASSWORD: 'Enter new password here',
      CONFIRM_PASSWORD: 'Confirm new password here',
    },
  },
  NO_USERS: "You don't have any users yet.",
  NO_USERS_HELPER_TEXT:
    'All of your users will be found here, regardless of the groups, policies and apps they have access to.',
  ADD_USER: 'Add User',
  USERS: 'Users',
  SEARCH_FOR_USERS: 'Search for users',
  SELECT_USERS: 'Select User(s)',
  USER_DETAILS: {
    TAB_NAMES: {
      USERS: 'Details',
      USER_GROUPS: GLOBAL_LABELS.USER_GROUPS,
      APPLICATIONS: GLOBAL_LABELS.APPLICATIONS,
      POLICIES: GLOBAL_LABELS.POLICIES,
      STATS: GLOBAL_LABELS.STATS,
      CREDS: GLOBAL_LABELS.CREDENTIALS,
    },
    BACK_TO_USERS: 'Back to Users',
    ACTIONS: 'Actions',
  },
  ADD_USER_GROUP: {
    HEADING: 'Assign User Groups',
    PLACEHOLER_SELECT: 'Enter or select user groups',
    SUB_HEADING:
      'Select groups to assign to this user. You may assign up to 50 groups per user',
    SUB_HEADING_APP:
      'Select groups to assign to this app. You may assign up to 50 groups per app',
    LABEL: 'Link to User Group(s)',
    PLACEHOLDER: 'Search for user groups',
  },
  DELETE_USER_MESSAGE: {
    HEADING: 'Delete User',
    CONFIRM_MESSAGE:
      'Are you really sure you want to delete {user}? This cannot be undone!',
  },
  USER_GROUPS_TAB: {
    HEADING: 'All groups that that this user has access to',
    BUTTON_LABEL: 'Add User Group',
    ASSIGN_USERGROUP: 'Assign User Group',
    TABLE_HEADS: {
      NAME: 'Name',
      NOOFUSERS: 'No. Of Users',
      STATS: 'Stats',
    },
  },
  USERS_ACCESS_MESSAGE_UNDER_APP: 'Users that have access to this app',
  USERS_EMPTY_STATE_UNDER_APP: 'There are no users assigned to this app yet',
  APP_ACCESS_MESSAGE_TO_USER:
    '{userName} will get access to {appName}. All relevant policies associated with {appName} will apply.',
  CUSTOM_REMOVE_UNDER_TABS_USER:
    'This action will remove {removeItem} for user {removeFrom}',
  LAST_LOGIN_TIME: 'Last Login time',
  POLICIES_HEADING: 'Policies used by this user',
  POLICIES_ACCESS_MESSAGE_TO_USER:
    '{policyName} will get added to  the list of policies for user {userName}',
  POLICY_ALREADY_EXISTS_FOR_USER:
    'This policy has already been assigned to this user',
};

export default userModuleMessages;
